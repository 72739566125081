import React from "react";
import "./header.css";

export default function Header() {
  function toTop() {
    window.scrollTo(0, 0);
  }

  window.addEventListener("scroll", function () {
    if (document.documentElement.scrollTop > 0) {
      document.querySelector(".header").classList.add("shadow");
    } else {
      document.querySelector(".header").classList.remove("shadow");
    }
  });

  return (
    <header className="header">
      <div className="logo" onClick={toTop}>
        <div className="logo-elle">ELLE</div>
        <div className="logo-store">S T O R E</div>
      </div>
      <div className="button">
        <div></div>
        <div></div>
      </div>
    </header>
  );
}
