import React from "react";
import "./shop.css";

export default function Shop(props) {
  return (
    <li>
      <a className="shop" href={props.href} target="_blank" rel="noreferrer">
        <img src={props.img} alt={props.title} className="shop__img" />
      </a>
    </li>
  );
}
