import "./App.css";
import Header from "./components/header/Header";
import Promo from "./components/promo/Promo";
import Shops from "./components/shops/Shops";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div className="container">
      <div className="App">
        <Header />
        <Promo />
        <Shops />
        <Footer />
      </div>
    </div>
  );
}

export default App;
