// Socials images
import instagram from "../img/socials/instagram.svg";
import telegram from "../img/socials/telegram.svg";
import whatsapp from "../img/socials/whatsapp.svg";

const socials = [
  {
    title: "instagram",
    img: instagram,
    href: "https://instagram.com/elle_st0re",
  },
  {
    title: "telegram",
    img: telegram,
    href: "https://t.me/ELLE_ST0RE",
  },
  {
    title: "whatsapp",
    img: whatsapp,
    href: "https://chat.whatsapp.com/L26nqoz8h5CJ4npgkpSS2N",
  },
];

export { socials };
