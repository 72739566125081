import React from "react";
import Shop from "../shop/Shop";
import { shops } from "../../helpers/shops";
import "./shops.css";

export default function Shops() {
  return (
    <section className="shops">
      <h2>Ссылки на турецкие магазины:</h2>
      <ul className="shops__list">
        {shops.map((shop, index) => {
          return (
            <Shop
              title={shop.title}
              img={shop.img}
              href={shop.href}
              key={index}
            />
          );
        })}
      </ul>
    </section>
  );
}
