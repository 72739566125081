import React from "react";
import { socials } from "../../helpers/socials";
import "./footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <ul className="footer__socials">
        {socials.map((social, index) => {
          return (
            <li key={index}>
              <a href={social.href}>
                <img src={social.img} alt={social.title} />
              </a>
            </li>
          );
        })}
      </ul>
      <div>© 2024 ELLE STORE, Inc</div>
    </footer>
  );
}
