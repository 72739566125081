// Shops images
import zara from "../img/shops/Zara.png";
import hm from "../img/shops/HM_logo.png";
import massimo from "../img/shops/Massimo_Dutti.png";
import mango from "../img/shops/Mango.png";
import bershka from "../img/shops/Bershka.png";
import pullbear from "../img/shops/Pull&Bear.png";
import waikiki from "../img/shops/lcwaikiki.png";
import tommy from "../img/shops/Tommy_hilfiger.png";
import calvin from "../img/shops/Calvin_klein.png";
import stradivarius from "../img/shops/Stradivarius.png";
import new_balance from "../img/shops/New_balance.png";
import nike from "../img/shops/Nike.png";

const shops = [
  {
    title: "Zara",
    img: zara,
    href: "https://www.zara.com/tr/en/",
  },
  {
    title: "H&M",
    img: hm,
    href: "https://www2.hm.com/tr_tr/index.html",
  },
  {
    title: "Massimo_Dutti",
    img: massimo,
    href: "https://www.massimodutti.com/tr/en/",
  },
  {
    title: "Mango",
    img: mango,
    href: "https://shop.mango.com/tr",
  },
  {
    title: "Bershka",
    img: bershka,
    href: "https://www.bershka.com/tr/en/",
  },
  {
    title: "Pull&Bear",
    img: pullbear,
    href: "https://www.pullandbear.com/tr",
  },
  {
    title: "LC-Waikiki",
    img: waikiki,
    href: "https://www.lcwaikiki.com/tr-TR/TR",
  },
  {
    title: "Tommy_hilfiger",
    img: tommy,
    href: "https://tr.tommy.com/",
  },
  {
    title: "Calvin_klein",
    img: calvin,
    href: "https://tr.calvinklein.com/",
  },
  {
    title: "Stradivarius",
    img: stradivarius,
    href: "https://www.stradivarius.com/tr/en/",
  },

  {
    title: "New Balance",
    img: new_balance,
    href: "https://www.newbalance.com.tr/",
  },
  {
    title: "Nike",
    img: nike,
    href: "https://www.nike.com/tr/",
  },
];

export { shops };
