import React from "react";
import "./promo.css";

export default function Promo() {
  return (
    <section className="promo">
      <h1 className="title">Одежда и обувь</h1>
    </section>
  );
}
